import { IconButton } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import blue from '@@styles/colors/blue';

import { ReactComponent as CloseIcon } from '../../../../images/icons/close.svg';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      padding: 0,
      // keyboard focus
      'body:not(.using-mouse) &:focus svg [class*="close"]': {
        opacity: 1,
      },
      '&.Mui-focusVisible': {
        borderRadius: '50%',
        outline: `3px solid ${blue.navy} !important`,
      },
      'body:not(.using-mouse) &:focus': {
        outline: '3px solid white',
      },
      '& svg': {
        color: common.white,
        '& [class*="close"]': {
          stroke: '#fff',
          opacity: 0.7,
        },
        '&:hover [class*="close"]': {
          opacity: 1,
        },
      },
      '& .MuiButton-iconSizeMedium svg': {
        width: '2.4em',
        height: '2.4em',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  });
});

interface Props {
  title: string;
  onClick: (e: any) => void;
  tabIndex: number;
  className: string;
}

const StyledCloseButton: React.FC<Props> = ({
  title,
  onClick,
  tabIndex = 0,
  className,
}) => {
  const styles = useStyles();

  return (
    <IconButton
      tabIndex={tabIndex}
      title={title}
      aria-label={title}
      onClick={onClick}
      classes={{ root: styles.root }}
      className={className}
      disableRipple
      disableFocusRipple
    >
      <CloseIcon/>
    </IconButton>
  );
};

export default StyledCloseButton;
