import { ClassNameMap } from '@material-ui/styles';
import { forwardRef, PropsWithChildren, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import OdFavouriteButton from '@@src/components/Buttons/OdFavouriteButton';
import Metadata from '@@src/components/Tiles/TilesV2/Metadata';
import OnDemand2 from '@@types/OnDemand2';

import BaseTile from './BaseTile';

/* eslint-disable react/require-default-props */
export interface SeriesTileProps {
  classes?: ClassNameMap;
  className?: string;
  displayType: '2:3' | '16:9';
  canBeFocused?: boolean;
  collectionTileItem: OnDemand2.CollectionItemSeries;
  onClick?: () => void;
  onActivate?: () => void;
  onDeactivate?: () => void;
  onFavourited?: (item: OnDemand2.CollectionItemSeries) => void;
  onUnfavourited?: (item: OnDemand2.CollectionItemSeries) => void;
  buttonType?: 'favourite' | 'close'
}
/* eslint-enable react/require-default-props */

const SeriesTile = forwardRef<HTMLDivElement, PropsWithChildren<SeriesTileProps>>((props, ref: RefObject<HTMLDivElement>) => {
  const {
    classes,
    className,
    displayType,
    canBeFocused,
    collectionTileItem,
    onClick,
    onActivate,
    onDeactivate,
    onFavourited,
    onUnfavourited,
    buttonType,
  } = props;

  const { t } = useTranslation('common');

  const seasonsAvailable = collectionTileItem.seasonCount ? t('metadata.seasonsAvailable', { count: collectionTileItem.seasonCount }) : undefined;

  return (
    <div
      ref={ref}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      className={className}
    >
      <BaseTile
        classes={classes}
        title={collectionTileItem.title}
        subtitle={collectionTileItem.genres[0]}
        metadata={(
          <Metadata
            list={[
              collectionTileItem.genres[0],
              collectionTileItem.language,
              seasonsAvailable,
            ]}
          />
        )}
        description={collectionTileItem.description}
        displayType={displayType}
        link={collectionTileItem.route}
        imageId={collectionTileItem.imageId}
        ctaButton={(
          <OdFavouriteButton
            entityId={collectionTileItem.id}
            entityType={collectionTileItem.catalogueEntityType}
            onClickAdd={() => {
              onFavourited(collectionTileItem);
            }}
            onClickRemove={() => {
              onUnfavourited(collectionTileItem);
            }}
            buttonType={buttonType}
          />
        )}
        onActivate={onActivate}
        onDeactivate={onDeactivate}
        canBeFocused={canBeFocused}
        onClick={onClick}
      />
    </div>
  );
});

export default SeriesTile;
