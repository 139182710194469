import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';

import ProgressBar, { ProgressBarProps } from './ProgressBar';

interface VideoProgressBarProps extends ProgressBarProps {
  /**
   * The position of the progress bar. `bottom` will absolutely position the component at the bottom. Default value is `relative`
   */
  position?: 'relative' | 'bottom';
}

const useStyles = makeStyles<Theme, VideoProgressBarProps>(() => {
  return createStyles({
    root: ({ position }) => {
      if (position === 'bottom') {
        return {
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
        };
      }
      return {};
    },
    progressBar: {
      height: 6,
    },
    progressBarFill: {},
  });
});

const VideoProgressBar: FunctionComponent<VideoProgressBarProps> = (props) => {
  const { progressPercent } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root} aria-hidden>
      <ProgressBar
        progressPercent={progressPercent ?? 0}
        classes={{
          root: classes.progressBar,
          progressBarFill: classes.progressBarFill,
        }}
      />
    </div>
  );
};

export default VideoProgressBar;
