import { IconButton } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Heart from '@@icons/Heart';

import { ReactComponent as CloseIcon } from '../../images/icons/close.svg';
import { ReactComponent as FavouriteIcon } from '../../images/icons/favourite.svg';
import blue from '../../styles/colors/blue';
import orange from '../../styles/colors/orange';
import Button from '../Inputs/Button';

export const useFavouriteButtonStyles = makeStyles(() => {
  return createStyles({
    root: {
      padding: 0,
      // not in favourite keyboard focus
      'body:not(.using-mouse) &:focus svg [class*="heart"]': {
        fill: common.white,
      },
      '&.Mui-focusVisible': {
        borderRadius: '50%',
        outline: `3px solid ${blue.navy} !important`,
      },
      '& svg': {
        color: common.white,
        '@media (hover: hover)': {
          // not in favourite hover
          '&:hover [class*="heart"]': {
            fill: common.white,
          },
          '&:hover, &:focus': {
            '& [class*="close"]': {
              opacity: 1,
            },
          },
        },
        '& [class*="circle"]': {
          fill: 'rgba(0,0,0,0.25)',
        },
        // x button
        '& [class*="close"]': {
          stroke: '#fff',
          opacity: 0.7,
        },
      },
      '&.in-favourite': {
        // in favourite keyboard focus
        'body:not(.using-mouse) &:focus svg': {
          '& [class*="circle"]': {
            color: common.white,
          },
          '& [class*="heart"]': {
            fill: orange.darkTangerine,
          },
        },
        '& svg': {
          color: orange.darkTangerine,
          '& [class*="heart"], &:hover [class*="heart"]': {
            fill: orange.darkTangerine,
          },
          // in favourite hover
          '@media (hover: hover)': {
            '&:hover [class*="circle"]': {
              color: common.white,
            },
          },
        },
      },
      '& .MuiButton-iconSizeMedium svg': {
        width: '2.4em',
        height: '2.4em',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  });
});

interface OdFavouriteButtonProps {
  buttonType?: 'favourite' | 'close';
  showLabel?: boolean;
  tabIndex?: number;
  classes?: any;
  onClick?: () => void;
  isFavourite: boolean
}

const OdFavouriteButtonPresenter: FunctionComponent<OdFavouriteButtonProps> = (props) => {
  const {
    isFavourite,
    tabIndex = 0,
    showLabel = false,
    onClick,
    buttonType = 'favourite',
  } = props;
  const classes = useFavouriteButtonStyles(props);
  const { t } = useTranslation('common');

  let label;
  if (isFavourite) {
    label = t('button.removeFromFavourites');
  } else {
    label = t('button.addToFavourites');
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  if (showLabel) {
    return (
      <Button
        alternateColor={isFavourite}
        buttonType="text"
        size="large"
        startIcon={<Heart/>}
        title={label}
        aria-label={label}
        onClick={handleOnClick}
        tabIndex={tabIndex}
      >
        {label}
      </Button>
    );
  }

  const Icon = buttonType === 'favourite' ? FavouriteIcon : CloseIcon;

  return (
    <IconButton
      title={label}
      aria-label={label}
      onClick={handleOnClick}
      tabIndex={tabIndex}
      classes={{ root: clsx(classes.root, isFavourite ? 'in-favourite' : '') }}
    >
      <Icon/>
    </IconButton>
  );
};

export default OdFavouriteButtonPresenter;
