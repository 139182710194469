import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

import Image from '@@src/components/Html/Image';
import TileConstants from '@@src/components/Tiles/TilesV2/TileConstants';
import grey from '@@styles/colors/grey';

export const useImageStyles = makeStyles(() => {
  return createStyles({
    imageContainer: {
      backgroundColor: grey.blackpearl,
      position: 'relative',
      transition: '.5s all',
      transitionDelay: '0.5s',
      border: `1px solid ${grey.darkBlackPearl}`,
      borderRadius: 4,
      overflow: 'hidden',
      height: 0,
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        '.active &': {
          filter: 'blur(3px)',
        },
      },
      '&:after': {
        content: '""',
        display: 'block',
        height: '100%',
        position: 'absolute',
        top: 0,
        width: '100%',
        opacity: 0,
        transition: TileConstants.animationTransition,
        zIndex: 1,
      },
      '.active &': {
        '&:after': {
          opacity: 1,
        },
      },
    },
    // force image to render in 16:9 aspect ratio if it comes back from the Image API in a different aspect ratio because the source image is smaller than the target resolution
    // NOTE: this can be removed if the Image API can always return the target resolution regardless of the source
    'imageContainer-16:9': {
      paddingTop: '55.25%',
      '.active &': {
        '&:after': {
          // apply scaling to gradient to minimise the pixel gap that renders on Chrome
          transform: 'scale(1.05)',
        },
      },
      '&:after': {
        backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.9) 48%, rgb(0, 0, 0) 100%)',
      },
    },
    'imageContainer-2:3': {
      paddingTop: '150%',
      '&:after': {
        backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.9) 48%, rgb(0, 0, 0) 100%)',
      },
    },
  });
});

interface Props {
  imageId?: string;
  imageSrc?: string;
  className?: string;
  displayType: '16:9' | '2:3';
}

const TileImage: React.FC<Props> = ({
  imageId,
  children,
  imageSrc,
  className,
  displayType,
}) => {
  const classes = useImageStyles();

  return (
    <div
      className={clsx(
        classes.imageContainer,
        classes[`imageContainer-${displayType}`],
        'prefers-reduced-motion',
        className,
      )}
    >
      <Image
        src={imageSrc ?? undefined}
        imageId={imageId ?? undefined}
        ratio="16:9"
        srcSetWidths={[400, 600, 800]}
        imageSizes={{
          xs: '50vw',
          sm: '33vw',
          all: '20vw',
        }}
        alt="title"
      />
      {children}
    </div>
  );
};

export default TileImage;
