import { ClassNameMap } from '@material-ui/styles';
import { RefObject } from 'react';

import OnDemand2 from '@@src/@types/OnDemand2';
import ContinueWatchingTile from '@@src/components/Tiles/TilesV2/components/ContinueWatchingTile';

import CollectionTile from './CollectionTile';
import PageTile from './PageTile';
import SeriesTile from './SeriesTile';
import VideoTile from './VideoTile';

interface CollectionItemTileProps {
  ref?: RefObject<HTMLDivElement>;
  classes?: ClassNameMap;
  className: string;
  displayType: '16:9' | '2:3';
  canBeFocused?: boolean;
  onClick: () => void;
  onKeyPress: (e: KeyboardEvent) => void;
  onActivate: () => void;
  onDeactivate: () => void;
  onFavourited?: () => void;
  onUnfavourited?: () => void;
  continueWatching?: boolean;
  progressPercent?: number;
  buttonType?: 'favourite' | 'close';
}

const CollectionItemTile: React.FC<{ item: OnDemand2.CollectionItem, props: CollectionItemTileProps }> = ({ item, props }) => {
  if (props.continueWatching && (item.entityType === 'Movie' || item.entityType === 'Episode' || item.entityType === 'Program')) {
    return (
      <ContinueWatchingTile
        ref={props.ref}
        collectionTileItem={item}
        onClick={props.onClick}
        className={props.className}
        tabIndex={props.canBeFocused ? 0 : -1}
      />
    );
  }

  switch (item.entityType) {
    case 'Movie':
    case 'Program':
    case 'Episode':
    case 'Clip':
      return (
        <VideoTile collectionTileItem={item} {...props}/>
      );
    case 'Series':
      return (
        <SeriesTile collectionTileItem={item} {...props}/>
      );
    case 'Collection':
      return (
        <CollectionTile
          ref={props.ref}
          classes={props.classes}
          className={props.className}
          displayType={props.displayType}
          canBeFocused={props.canBeFocused}
          collectionTileItem={item}
          onActivate={props.onActivate}
          onDeactivate={props.onDeactivate}
          onClick={props.onClick}
        />
      );
    case 'Page':
      return (
        <PageTile collectionTileItem={item} {...props}/>
      );
    default:
      return null;
  }
};

export default CollectionItemTile;
