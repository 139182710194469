import { useCallback, useEffect } from 'react';

import OdFavouriteButtonPresenter from '@@src/components/Buttons/OdFavouriteButtonPresenter';
import { dispatchOpenSignIn } from '@@src/components/Login/LoginForm';
import { useAppDispatch, useAppSelector } from '@@src/hooks/store';
import {
  addFavouriteAsyncThunk,
  allFavourites,
  removeFavouriteAsyncThunk,
} from '@@stores/FavouritesStore';
import { getIsLoggedIn, getIsReady } from '@@stores/UserStore';

interface ContainerProps {
  entityId: string;
  entityType: 'MOVIE' | 'TV_SERIES' | 'NEWS_SERIES' | 'SPORTS_SERIES' | 'TV_PROGRAM' | 'NEWS_PROGRAM' | 'SPORTS_PROGRAM' | 'CURATED_COLLECTION' | 'DYNAMIC_COLLECTION' | 'PAGE' | 'PERSON';
  autoAdd?: boolean;
  tabIndex?: number;
  showLabel?: boolean;
  onClickAdd?: () => void;
  onClickRemove?: () => void;
  classes?: { root?: string; };
  buttonType?: 'favourite' | 'close';
}

const OdFavouriteButton = ({
  entityId,
  entityType,
  autoAdd = false,
  tabIndex = 0,
  showLabel = false,
  onClickAdd,
  onClickRemove,
  classes,
  buttonType,
}: ContainerProps) => {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector(getIsLoggedIn);
  const loginStatusIsReady = useAppSelector(getIsReady);
  const favourites = useAppSelector(allFavourites);

  const inFavourite = Boolean(
    favourites.find((favourite) => {
      return favourite.entityId === entityId;
    }),
  );

  const handleOnFavourite = useCallback((forcedAdd: boolean = false) => {
    if (!inFavourite) {
      if (onClickAdd) {
        onClickAdd();
      }

      if (!loggedIn) {
        dispatchOpenSignIn(addFavouriteAsyncThunk({
          entityId,
          entityType,
        }) as any);
      } else {
        dispatch(addFavouriteAsyncThunk({
          entityId,
          entityType,
        }));
      }
    }

    if (inFavourite && !forcedAdd) {
      if (onClickRemove) {
        onClickRemove();
      }

      if (!loggedIn) {
        dispatchOpenSignIn(removeFavouriteAsyncThunk({
          entityId,
          entityType,
        }) as any);
      } else {
        dispatch(removeFavouriteAsyncThunk({
          entityId,
          entityType,
        }));
      }
    }
  }, [dispatch, inFavourite, entityId, entityType, loggedIn, onClickAdd, onClickRemove]);

  const handleOnClick = () => {
    handleOnFavourite();
  };

  useEffect(() => {
    if (loginStatusIsReady && autoAdd) {
      handleOnFavourite(true);
    }
  }, [autoAdd, loginStatusIsReady, handleOnFavourite]);

  return (
    <OdFavouriteButtonPresenter
      classes={classes}
      tabIndex={tabIndex}
      showLabel={showLabel}
      buttonType={buttonType}
      onClick={handleOnClick}
      isFavourite={inFavourite}
    />
  );
};

export default OdFavouriteButton;
