import { common } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { forwardRef, RefObject, PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import VideoProgressBar from '@@src/components/ProgressBar/VideoProgressBar';
import TileConstants from '@@src/components/Tiles/TilesV2/TileConstants';
import TileContainer from '@@src/components/Tiles/TilesV2/TileContainer';
import TileFooter from '@@src/components/Tiles/TilesV2/TileFooter';
import TileImage from '@@src/components/Tiles/TilesV2/TileImage';
import StyledCloseButton from '@@src/components/Tiles/TilesV2/components/StyledCloseButton';
import { deleteContinueWatchingItemsAsyncThunk } from '@@stores/continueWatchingSlice';
import blue from '@@styles/colors/blue';
import OnDemand2 from '@@types/OnDemand2';
import { getTileGrowRatio } from '@@utils/helpers';

import { ReactComponent as PlayIcon } from '../../../../images/icons/play-circle.svg';

const useStyles = makeStyles(() => {
  return createStyles({
    hoverContainer: {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      zIndex: 99,
      display: 'flex',
    },
    overlay: {
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0,
      transition: 'opacity 300ms',
      '&:hover': {
        opacity: 1,
      },
      'a[data-focus-visible-added] > &': {
        opacity: 1,
      },
    },
    playIcon: {
      width: 48,
      height: 48,
      opacity: 0.8,
      '&:hover': {
        opacity: 0.9,
      },
    },
    progressBarContainer: {
      // @ts-ignore required
      visibility: 'visible !important',
    },
    progressBar: {
      borderRadius: '0 0 4px 4px',
      height: 6,
    },
    progressBarFill: {
      borderRadius: '0 0 0px 4px',
      height: 6,
      '&.completed': {
        borderRadius: '0 0 4px 4px',
      },
    },
    playButtonContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '3.3rem',
      padding: 0,
      position: 'absolute',
      top: 45,
      left: 0,
      right: 0,
      textAlign: 'center',
    },
    buttonContainer: {
      position: 'absolute',
      zIndex: 3,
      top: 12.5,
      right: 12.5,
      opacity: 1,
      transition: TileConstants.animationTransition,
      '$activableContent-16_9 &': {
        transform: `scale(${3 - getTileGrowRatio(16 / 9)})`,
      },
    },
    root: {
      padding: 0,
      // keyboard focus
      'body:not(.using-mouse) &:focus svg [class*="close"]': {
        opacity: 1,
      },
      '&.Mui-focusVisible': {
        borderRadius: '50%',
        outline: `3px solid ${blue.navy} !important`,
      },
      'body:not(.using-mouse) &:focus': {
        outline: '3px solid white',
      },
      '& svg': {
        color: common.white,
        '& [class*="close"]': {
          stroke: '#fff',
          opacity: 0.7,
        },
        '&:hover [class*="close"]': {
          opacity: 1,
        },
      },
      '& .MuiButton-iconSizeMedium svg': {
        width: '2.4em',
        height: '2.4em',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  });
});

// ignoreFunctionalComponents does not work for forwardRef https://github.com/yannickcr/eslint-plugin-react/issues/2856
/* eslint-disable react/require-default-props */
export interface ContinueWatchingTileProps {
  collectionTileItem:
  | OnDemand2.CollectionItemMovie
  | OnDemand2.CollectionItemEpisode
  | OnDemand2.CollectionItemProgram
  className?: string;
  onClick?: () => void;
  tabIndex?: number
}

export const useContinueWatchingTileLabel = (collectionTileItem: ContinueWatchingTileProps['collectionTileItem']) => {
  const { t } = useTranslation(['common']);

  if (collectionTileItem.entityType === 'Episode') {
    if (collectionTileItem.seasonNumber > 0) {
      return {
        title: collectionTileItem.seriesTitle,
        subtitle: `${collectionTileItem.progressDecoration} - S${collectionTileItem.seasonNumber} E${collectionTileItem.episodeNumber}`,
        ariaLabel: `${collectionTileItem.progressDecoration} ${t('season')} ${collectionTileItem.seasonNumber} ${t('episode')} ${collectionTileItem.episodeNumber} ${collectionTileItem.seriesTitle}`,
      };
    }
    return {
      title: collectionTileItem.seriesTitle,
      subtitle: `${collectionTileItem.progressDecoration} - E${collectionTileItem.episodeNumber}`,
      ariaLabel: `${collectionTileItem.progressDecoration} ${t('episode')} ${collectionTileItem.episodeNumber} ${collectionTileItem.seriesTitle}`,
    };
  }

  return {
    title: collectionTileItem.title,
    subtitle: collectionTileItem.progressDecoration,
    ariaLabel: `${collectionTileItem.progressDecoration} ${collectionTileItem.title}`,
  };
};

const ContinueWatchingTile = forwardRef<HTMLDivElement, PropsWithChildren<ContinueWatchingTileProps>>((props, ref: RefObject<HTMLDivElement>) => {
  const {
    onClick,
    tabIndex,
    className,
    collectionTileItem,
  } = props;

  const styles = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const buttonTitle = t('button.removeFromContinueWatching');

  const handleRemoveFromContinueWatching = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      deleteContinueWatchingItemsAsyncThunk({
        entityId: collectionTileItem.id,
      }),
    );
  }, [dispatch, collectionTileItem.id]);

  const { title, subtitle, ariaLabel } = useContinueWatchingTileLabel(collectionTileItem);

  return (
    <TileContainer
      ref={ref}
      className={className}
      link={{
        name: 'watch',
        params: { id: collectionTileItem.mpxMediaId },
        ariaLabel,
      }}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      <TileImage
        imageId={collectionTileItem.imageId}
        displayType="16:9"
      >
        <div className={clsx(styles.hoverContainer, styles.overlay)}>
          <PlayIcon
            className={styles.playIcon}
          />
          <StyledCloseButton
            tabIndex={tabIndex}
            title={buttonTitle}
            onClick={handleRemoveFromContinueWatching}
            className={styles.buttonContainer}
          />
        </div>

        <VideoProgressBar
          classes={{
            root: styles.progressBarContainer,
            progressBar: styles.progressBar,
            progressBarFill: styles.progressBarFill,
          }}
          progressPercent={collectionTileItem.progressPercent}
          position="bottom"
          transparent
        />
      </TileImage>

      <TileFooter
        title={title}
        subtitle={subtitle}
      />
    </TileContainer>
  );
});

export default ContinueWatchingTile;
