import { IconButton } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/styles';
import { Duration } from 'luxon';
import { forwardRef, RefObject, PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import OnDemand2 from '@@src/@types/OnDemand2';
import OdFavouriteButton from '@@src/components/Buttons/OdFavouriteButton';
import Metadata from '@@src/components/Tiles/TilesV2/Metadata';
import AvailableDateTimeLabel from '@@src/components/Utils/AvailableDateTimeLabel';
import ClassificationBadge from '@@src/components/Utils/ClassificationBadge';
import { durationShortFormatParams, durationShortFormatParamsWithSeconds } from '@@src/i18n';
import { deleteContinueWatchingItemsAsyncThunk } from '@@stores/continueWatchingSlice';
import blue from '@@styles/colors/blue';

import { ReactComponent as CloseIcon } from '../../../images/icons/close.svg';
import BaseTile from './BaseTile';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      padding: 0,
      // keyboard focus
      'body:not(.using-mouse) &:focus svg [class*="close"]': {
        opacity: 1,
      },
      '&.Mui-focusVisible': {
        borderRadius: '50%',
        outline: `3px solid ${blue.navy} !important`,
      },
      'body:not(.using-mouse) &:focus': {
        outline: '3px solid white',
      },
      '& svg': {
        color: common.white,
        '& [class*="close"]': {
          opacity: 0.7,
        },
        '&:hover [class*="close"]': {
          opacity: 1,
        },
      },
      '& .MuiButton-iconSizeMedium svg': {
        width: '2.4em',
        height: '2.4em',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  });
});

// ignoreFunctionalComponents does not work for forwardRef https://github.com/yannickcr/eslint-plugin-react/issues/2856
/* eslint-disable react/require-default-props */
export interface VideoTileProps {
  collectionTileItem: OnDemand2.CollectionItemMovie | OnDemand2.CollectionItemEpisode | OnDemand2.CollectionItemProgram | OnDemand2.CollectionItemClip
  displayType: '2:3' | '16:9';
  className?: string;
  onFavourited?: (item: Partial<OnDemand2.CollectionItemVideo | OnDemand2.CollectionItemSeries>) => void;
  onUnfavourited?: (item: Partial<OnDemand2.CollectionItemVideo | OnDemand2.CollectionItemSeries>) => void;
  onActivate?: () => void;
  onDeactivate?: () => void;
  canBeFocused?: boolean;
  onClick?: () => void;
  classes?: ClassNameMap;
  continueWatching?: boolean;
  buttonType?: 'favourite' | 'close'
}

const VideoCtaButton = ({
  collectionTileItem,
  continueWatching,
  onFavourited,
  onUnfavourited,
  buttonType,
  onClick,
}: Pick<VideoTileProps, 'collectionTileItem' | 'continueWatching' | 'onFavourited' | 'onUnfavourited' | 'buttonType' | 'onClick'>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const title = t('button.removeFromContinueWatching');

  const handleRemoveFromContinueWatching = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      deleteContinueWatchingItemsAsyncThunk({
        entityId: collectionTileItem.id,
      }),
    );
    if (onClick) {
      onClick();
    }
  }, [dispatch, collectionTileItem.id, onClick]);

  if (continueWatching) {
    return (
      <IconButton
        title={title}
        aria-label={title}
        onClick={handleRemoveFromContinueWatching}
        classes={{ root: classes.root }}
        disableRipple
        disableFocusRipple
      >
        <CloseIcon/>
      </IconButton>
    );
  }

  if (
    collectionTileItem.catalogueEntityType === 'CLIP'
    || collectionTileItem.catalogueEntityType === 'TV_EPISODE'
    || collectionTileItem.catalogueEntityType === 'NEWS_EPISODE'
    || collectionTileItem.catalogueEntityType === 'SPORTS_EPISODE'
  ) {
    return null;
  }

  return (
    <OdFavouriteButton
      entityId={collectionTileItem.id}
      entityType={collectionTileItem.catalogueEntityType}
      onClickAdd={() => {
        onFavourited(collectionTileItem);
      }}
      onClickRemove={() => {
        onUnfavourited(collectionTileItem);
      }}
      buttonType={buttonType}
    />
  );
};

const VideoMetaData = ({ collectionTileItem }: { collectionTileItem: VideoTileProps['collectionTileItem'] }) => {
  const { t } = useTranslation('common');

  let metadata = [];
  let classificationBadge;
  let duration;

  if (collectionTileItem.duration) {
    const durationSeconds = Duration.fromISO(collectionTileItem.duration).as('seconds');
    duration = t('video.duration', {
      duration: Duration.fromISO(collectionTileItem.duration).as('seconds'),
      formatParams: {
        duration: durationSeconds >= 60 ? durationShortFormatParams : durationShortFormatParamsWithSeconds,
      },
    });
  }

  switch (collectionTileItem.entityType) {
    case 'Movie':
      metadata.push(collectionTileItem.genres[0]);
      metadata.push(collectionTileItem.language);
      metadata.push(collectionTileItem.releaseYear);
      break;

    case 'Program':
      metadata.push(collectionTileItem.genres[0]);
      metadata.push(collectionTileItem.language);
      metadata.push(duration);

      if (collectionTileItem.isLiveStream === true) {
        if (!collectionTileItem.isAvailable) {
          metadata.push(<AvailableDateTimeLabel start={collectionTileItem.availableDate}/>);
        }
      }
      break;

    case 'Episode':
      metadata.push(collectionTileItem.seriesTitle);
      metadata.push(duration);

      if (collectionTileItem.seriesType === 'tv' && collectionTileItem.classificationId) {
        classificationBadge = <ClassificationBadge classification={collectionTileItem.classificationId}/>;
        metadata.push(classificationBadge);
      }
      break;

    case 'Clip':
      metadata.push(collectionTileItem.genres[0]);
      metadata.push(duration);

      if (collectionTileItem.classificationId) {
        classificationBadge = <ClassificationBadge classification={collectionTileItem.classificationId}/>;
        metadata.push(classificationBadge);
      }
      break;

    default:
      break;
  }

  metadata = metadata.filter((el) => {
    return el !== '';
  });

  return (
    <Metadata
      list={metadata}
    />
  );
};

const VideoTile = forwardRef<HTMLDivElement, PropsWithChildren<VideoTileProps>>((props, ref: RefObject<HTMLDivElement>) => {
  const {
    classes,
    displayType,
    canBeFocused,
    collectionTileItem,
    onClick,
    onActivate,
    onDeactivate,
    onFavourited,
    onUnfavourited,
    className,
    continueWatching = false,
    buttonType,
  } = props;

  const showPlayIcon = (
    collectionTileItem.entityType === 'Program'
      && collectionTileItem.isLiveStream === true
      && collectionTileItem.isAvailable
  )
    || collectionTileItem.entityType === 'Clip'
    || collectionTileItem.entityType === 'Episode';

  const subtitle = collectionTileItem.entityType === 'Episode'
    ? collectionTileItem.seriesTitle
    : collectionTileItem.genres[0];

  return (
    <div
      ref={ref}
      className={className}
    >
      <BaseTile
        classes={classes}
        title={collectionTileItem.title}
        subtitle={subtitle}
        description={collectionTileItem.description}
        displayType={displayType}
        link={collectionTileItem.route}
        imageId={collectionTileItem.imageId}
        playIcon={showPlayIcon}
        onActivate={onActivate}
        onDeactivate={onDeactivate}
        canBeFocused={canBeFocused}
        onClick={onClick}
        progressPercent={collectionTileItem.progressPercent}
        metadata={(
          <VideoMetaData
            collectionTileItem={collectionTileItem}
          />
        )}
        ctaButton={(
          <VideoCtaButton
            collectionTileItem={collectionTileItem}
            continueWatching={continueWatching}
            onFavourited={onFavourited}
            onUnfavourited={onUnfavourited}
            buttonType={buttonType}
            onClick={() => { if (onDeactivate) onDeactivate(); }}
          />
        )}
      />
    </div>
  );
});

export default VideoTile;
