// transition is ease-out-cubic https://easings.net/#easeOutCubic

const tileAnimationConfig = {
  transitionDuration: 400,
};

const animationTransition = `${tileAnimationConfig.transitionDuration}ms all cubic-bezier(0.33, 1, 0.68, 1)`;

export default {
  animationTransition,
  tileAnimationConfig,
};
