import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { forwardRef, PropsWithChildren } from 'react';

import { OdLink } from '@@src/routes';
import blue from '@@styles/colors/blue';
import OnDemand2 from '@@types/OnDemand2';

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      position: 'relative',
    },
    tile: {
      borderRadius: 0,
      position: 'relative',
      width: '100%',
      top: 0,
      left: 0,
      '&:focus-within': {
        '& div': {
          opacity: 1,
        },
      },
    },
    link: {
      display: 'block',
      padding: '0 4px',
      '&:focus': {
        outline: `3px solid ${blue.navy}`,
      },
    },
  });
});

/* eslint-disable react/require-default-props */
export type Props = {
  link?: OnDemand2.OdLinkProps
  onClick?: () => void
  className?: string
  linkStyles?: any
  onMouseHover?: () => void
  onMouseLeave?: () => void
  tabIndex?: number
};

const TileContainer = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({
  link,
  onClick,
  children,
  className,
  linkStyles,
  onMouseHover,
  onMouseLeave,
  tabIndex = 0,
}, ref) => {
  const classes = useStyles();

  return (
    <div ref={ref} className={clsx(classes.root, className)}>
      <OdLink
        name={link.name}
        params={link.params}
        role="link"
        onClick={onClick}
        tabIndex={tabIndex}
        className={clsx(classes.link, linkStyles)}
        onMouseEnter={onMouseHover}
        onMouseLeave={onMouseLeave}
        aria-label={link.ariaLabel}
      >
        <div
          className={clsx(classes.tile)}
        >
          {children}
        </div>
      </OdLink>
    </div>
  );
});

export default TileContainer;
