import { Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import grey from '@@styles/colors/grey';
import fontFamily from '@@styles/typography/fontFamily';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    tileContent: {
      padding: '10px',
      borderRadius: 4,
      [theme.breakpoints.down('xs')]: {
        padding: '8px',
      },
      textAlign: 'left',
    },
    tileTitle: {
      overflow: 'hidden',
      fontWeight: 500,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '1rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.875rem',
      },
      lineHeight: 1.125,
      '& + div': {
        marginTop: 4,
      },
      fontFamily: fontFamily.roboto,
    },
    subtitle: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      color: grey.chateau,
      display: 'flex',
      fontSize: '0.875rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.8125rem',
      },
      lineHeight: 1.2,
      '& > span:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
      minHeight: '1.2em',
      paddingTop: 2,
      fontFamily: fontFamily.primary,
    },
  });
});

interface Props {
  title: string;
  subtitle?: string;
}

const TileFooter: React.FunctionComponent<Props> = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <div className={classes.tileContent}>
      <Typography variant="body1" className={classes.tileTitle}>
        {title}
      </Typography>
      {
        subtitle && (
          <div className={classes.subtitle}>
            {subtitle}
          </div>
        )
      }
    </div>
  );
};

export default TileFooter;
